.pm2 {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.pm2 iframe {
  border: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}