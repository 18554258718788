
#logs-container {
  text-align: center;
}

#map-box {
  border: 0 solid var(--color-background);
  border-width: 10px 0;
  box-shadow: 0 0 8px 4px lightgray;
  height: 400px;
  margin: 24px auto 20px auto;
  width: 60%;
  max-width: 800px;
}

.logs {
  /* border: 1px solid lightgray; */
  box-shadow: 0 0 8px 4px lightgray;
  /* border-radius: 10px; */
  list-style: none;
  margin: 0 auto;
  padding: 4px 0;
  width: 60%;
  max-width: 800px;
  text-align: left;
}

.logs li {
  font-size: 12px;
  margin: 2px;
  padding: 4px 2px;
}
.logs li:not(:first-child) {
  border-top: 1px solid lightgray;
}

.logs li p {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.logs li .index {
  border-right: 1px solid gray;
  font-weight: bolder;
  margin: 0;
  padding: 0 4px 0 0;
  text-align: right;
  width: 30px;
}

.logs li .time {
  border-right: 1px solid gray;
  color: gray;
  font-style: italic;
  text-align: center;
  width: 180px;
}

.logs li .ip {
  border-right: 1px solid gray;
  text-align: center;
  width: 160px;
}
.logs li .request {
  margin-left: 6px;
}

.page-controls {
  padding: 20px;
}

.page-controls button {
  background-color: var(--color-background);
  border-radius: 4px;
  border: 0 solid var(--color-background);
  color: var(--color-foreground);
  cursor: pointer;
  font-size: 12px;
  margin: 0 10px;
}
.page-controls button:disabled {
  background-color: lightgray;
  cursor: default;
}
